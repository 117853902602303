// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiphp: 'https://api.mairiporaeducasim.com.br/api/v1/',
  // apiphp: 'http://localhost:8085/api/v1/',
  VAPID_PUBLIC_KEY:"BKqyS9QgI0cgPyyMXfcwgBuYZWqHJUDBi92ahzShNfQ9b1R0xxPcoD46LavwfAO2rSzWR3xv_OieB9ZMBs9q4hY",
  VAPID_PRIVATE_KEY:"cE06iwgQ-vshyY2b6GwVexjFqDZpTcVAGogX883utyU"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
