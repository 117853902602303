import { environment } from 'src/environments/environment';
import { PushService } from './_services/push.service';
import { Component } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public readonly VAPID_PUBLIC_KEY = environment.VAPID_PUBLIC_KEY;
  public anoAtual;

  public appPages = [
    { title: 'Home', url: '', icon: 'home' },
    { title: 'Instituição', url: '/instituicao', icon: 'book' },
    { title: 'Notícias', url: '/noticias', icon: 'newspaper' },
    { title: 'Escolas', url: '/escolas', icon: 'school' },
    { title: 'Mural', url: '/mural', icon: 'calendar-clear' },
    { title: 'Eventos', url: '/eventos', icon: 'megaphone' },
    { title: 'Contato', url: '/contato', icon: 'call' },
  ];

  constructor(
    private swPush: SwPush,
    private pushService: PushService,
    private router: Router,
    private navController: NavController,
    public platfomr: Platform,
    public alertController: AlertController
  ) {
    this.backButtonEvent();

    this.anoAtual = new Date().getUTCFullYear();

    //SE FOR ANDROID OU IOS
    if (Capacitor.getPlatform() !== 'web') {
      this.pushService.registerPush();
    }
    //SE FOR PWA
    else {
      this.subscribeToNotifications();
    }
  }

  subscribeToNotifications(): any {
    this.swPush
      .requestSubscription({
        serverPublicKey: this.VAPID_PUBLIC_KEY,
      })
      .then((sub) => {
        const token = JSON.parse(JSON.stringify(sub));

        this.pushService.salvarTokenPwa(token).subscribe(
          (res) => {
            console.log('SUBSCRIBED');
          },
          (error) => {
            console.log('Error: ', error);
          }
        );
      })
      .catch((error) => console.log('Error: ', error));
  }

  backButtonEvent() {
    this.platfomr.backButton.subscribeWithPriority(10, () => {
      const currentUrl = this.router.url;

      if (currentUrl === '/') {
        navigator['app'].exitApp();
      } else {
        this.navController.back();
      }
    });
  }
}
