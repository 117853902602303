import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PushService {
  private apiPhp = environment.apiphp;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private httpClient: HttpClient) {}

  registerPush() {
    PushNotifications.requestPermissions().then((permission) => {
      if (permission.receive) {
        PushNotifications.register();
      }
    });

    PushNotifications.addListener('registration', (token) => {
      const data = {
        device_token: token.value,
      };

      this.salvarTokenFirebase(data).subscribe(
        (res) => {
          console.log('Token Salvo!');
        },
        (error) => {
          console.log('Erro: ', error.message);
        }
      );
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log('Erro: ' + JSON.stringify(error));
    });

    // PushNotifications.addListener(
    //   'pushNotificationReceived',
    //   async (notification) => {
    //     console.log('Push received: ' + JSON.stringify(notification));
    //   }
    // );
  }

  salvarTokenFirebase(token) {
    return this.httpClient.post(
      this.apiPhp.concat('app-portalcomunidade/salvar-token-firebase'),
      token,
      this.httpOptions
    );
  }

  salvarTokenPwa(token) {
    return this.httpClient.post(
      this.apiPhp.concat('app-portalcomunidade/push-save'),
      token,
      this.httpOptions
    );
  }
}
